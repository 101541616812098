<template>
<!-- 
  Stepper에서 제외할 항목
  SSSSC00005 : 완료
  SSSSC00006 : 기각
-->
  <c-step
    stepperGrpCd="SAI_SUGGESTION_STATUS_STEP_CD"
    :param="param"
    :stepRejectItems="stepRejectItems"
    :currentStepCd="suggestionStepCd"
    v-model="suggestionStepCd"
    @getDetail="getDetail"
    @setSaiSugSuggestionId="setSaiSugSuggestionId"
    @closePopup="closePopup"
  >
  </c-step>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'suggestionDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSugSuggestionId: '',
      }),
    },
  },
  data() {
    return {
      suggestion: {
        saiSugSuggestionId: '',
        suggestionStepCd: '',
        suggestionNo: '',
        plantCd: '',
        regDeptCd: '',
        execDeptCd: '',
        suggestionDate: '',
        suggestionStatus: '',
        suggestionTitle: '',
        suggestionProblem: '',
        suggestDivisionTypeCd: null,
        suggestCategoryTypeCd: null,
        suggestionSuggest: '',
        suggestionBenefit: '',
        suggestionImprove: '',
        suggestionLocation: '',
        locationEtc: '',
        suggestionFirstAuditCd: '',
        suggestionSecondAuditCd: '',
        suggestionFirstAuditOpinion: '',
        suggestionSecondAuditOpinion: '',
        suggestionTypeEffect: '',
        suggestionIntangibleEffect: '',
        bountyFirst: '',
        bountySecond: '',
        sysApprovalRequestId: '',
        approvalStatusCd: '',
        vendorCd: '',
        approvalRequestFlag: '',
        regUserId: '',
        chgUserId: '',
      },
      suggestionStepCd: 'SSSSC00001',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD',
        taskKey: '',
      },
      attachImprInfo: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD_IMPR',
        taskKey: '',
      },
      editable: true,
    };
  },
  watch: {
  },
  computed: {   //computed는 setter가 없으니 지정해줄 수 없음
    param() {
      return {
        saiSugSuggestionId: this.popupParam.saiSugSuggestionId,
        suggestion: this.suggestion,
        attachInfo: this.attachInfo,
        attachImprInfo: this.attachImprInfo,
      }
    },
    isOld() {
      return Boolean(this.popupParam.saiSugSuggestionId)
    },
    stepRejectItems() {
      let rejectItems = ['SSSSC00005', 'SSSSC00006'];
      if (this.suggestion.suggestionStepCd === 'SSSSC00006') {
        rejectItems = ['SSSSC00003', 'SSSSC00004', 'SSSSC00005', 'SSSSC00006'];
      }
      return rejectItems;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.sug.suggestion.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      // computed에서 setter를 못해주기에 이곳에서 확인
      if (this.popupParam.saiSugSuggestionId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiSugSuggestionId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.suggestion, _result.data);
          // 첨부파일 key 설정
          this.$set(this.attachInfo, 'taskKey', this.popupParam.saiSugSuggestionId)
          this.$set(this.attachImprInfo, 'taskKey', this.popupParam.saiSugSuggestionId)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.setStep();
        },);
      } else {
        this.suggestion.suggestionDate = this.$comm.getToday();
        this.suggestion.regUserId = this.$store.getters.user.userId;
        this.suggestion.vendorCd = this.$store.getters.user.userId;
      }
    },
    setSaiSugSuggestionId(saiSugSuggestionId) {
      this.$set(this.popupParam, 'saiSugSuggestionId', saiSugSuggestionId);
      this.getDetail();
    },
    setStep() {
      this.suggestionStepCd = this.suggestion.suggestionStepCd;
      if (!this.isOld) {
        this.suggestionStepCd = 'SSSSC00001';
      } else if (this.suggestion.suggestionStepCd === 'SSSSC00005') {
        this.suggestionStepCd = 'SSSSC00004';
      } else if (this.suggestion.suggestionStepCd === 'SSSSC00006') {
        this.suggestionStepCd = 'SSSSC00002';
      }
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
