var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "SAI_SUGGESTION_STATUS_STEP_CD",
      param: _vm.param,
      stepRejectItems: _vm.stepRejectItems,
      currentStepCd: _vm.suggestionStepCd,
    },
    on: {
      getDetail: _vm.getDetail,
      setSaiSugSuggestionId: _vm.setSaiSugSuggestionId,
      closePopup: _vm.closePopup,
    },
    model: {
      value: _vm.suggestionStepCd,
      callback: function ($$v) {
        _vm.suggestionStepCd = $$v
      },
      expression: "suggestionStepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }